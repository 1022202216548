import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AlertContainer, Dock, Progress } from 'summer';
import useModalFix from './hooks/useModalFix';
import Routes from './Routes';
import { store } from './store';
import HabilitarAutomatizacaoIntegraContadorModalView from './views/components/habilitarAutomatizacaoIntegraContador/HabilitarAutomatizacaoIntegraContadorModalView';
import HeaderVideosTour from './views/components/header/HeaderVideosTour';
import IntegracaoContabilTour from './views/components/integracaoContabilTour/IntegracaoContabilTour';

import './App.css';
import './themes/DarkTheme.css';
import './themes/GreyTheme.css';
import './themes/NoirTheme.css';

const App: FC = () => {
  useModalFix();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer position="top-right" newestOnTop={true} />
        <AlertContainer />
        <Progress />
        <Routes />
        <Dock />
        <HeaderVideosTour />
        <IntegracaoContabilTour />
        <HabilitarAutomatizacaoIntegraContadorModalView />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
