import { alertManager, AlertType } from 'summer';
import { GlobalParameter } from '../models/dtos/ger/globalParameter';

export const promisedAlert = (message, type = AlertType.WARNING_YES_NO, onOkClick = null, textWarningNo = null, textWarningYes = null) =>
  new Promise(resolve => {
    alertManager.emit({
      message,
      onOkClick: onOkClick ? onOkClick : () => resolve(),
      textWarningNo,
      textWarningYes,
      type,
    });
  });

export function isClientePago(globalParameter: GlobalParameter): boolean {
  return globalParameter.acesso.gratuito;
}

export function acessoSpedContabil(globalParameter: GlobalParameter): boolean {
  return !globalParameter.acesso.geracaoSpedContabil;
}

export function gerarDIRF(globalParameter: GlobalParameter): boolean {
  return !globalParameter.acesso.geracaoDIRF;
}

export function gerarRAIS(globalParameter: GlobalParameter): boolean {
  return !globalParameter.acesso.geracaoRAIS;
}

export function gerarSEFIP(globalParameter: GlobalParameter): boolean {
  return !globalParameter.acesso.geracaoSEFIP;
}

export function acessoSpedFiscal(globalParameter: GlobalParameter): boolean {
  return !globalParameter.acesso.geracaoSpedFiscal;
}

export function acessoSpedFolha(globalParameter: GlobalParameter): boolean {
  return !globalParameter.acesso.geracaoSpedFolha;
}

export function acessoSuporteTicket(globalParameter: GlobalParameter): boolean {
  return !globalParameter.acesso.suporteTicket;
}
