import { createAction, handleActions } from 'redux-actions';
import { Notificacao } from '../../models/dtos/ger/notificacao';

export interface NotificationState {
  data: Notificacao[];
}

const initialState: NotificationState = {
  data: [],
};

export const setNotifications = createAction('SET_NOTIFICATIONS');
export const removeNotification = createAction('REMOVE_NOTIFICATION');
export const updateNotification = createAction('UPDATE_NOTIFICATION');
export const clearNotifications = createAction('CLEAR_NOTIFICATION');

export const reducer = handleActions<NotificationState>(
  {
    [setNotifications.toString()]: (state: NotificationState, action: any) => {
      const novasNotificacoes = action.payload.novasNotificacoes;
      const notificacoesRemover: Notificacao[] = [];

      novasNotificacoes.forEach((novaNotificacao: Notificacao) => {
        const indiceNotificacao = indexOfNotifications(state.data, novaNotificacao, action.payload.notificationType, compareNotifications);

        if (indiceNotificacao > -1) {
          const notificacaoAntiga = state.data[indiceNotificacao];
          notificacoesRemover.push(notificacaoAntiga);
        }
      });

      const newState = state.data.filter(it => !notificacoesRemover.includes(it));

      return {
        ...state,
        data: [...newState, ...action.payload.novasNotificacoes],
      };
    },
    [removeNotification.toString()]: (state: NotificationState, action: any) => {
      return {
        ...state,
        data: state.data.filter(it => it.codigo !== action.payload.codigo),
      };
    },
    [updateNotification.toString()]: (state: NotificationState, action: any) => {
      return {
        ...state,
        data: state.data.map(it => {
          const result = it;
          if (result.jobId === action.payload.jobId) {
            result.mensagem = action.payload.message;
          }
          return result;
        }),
      };
    },
    [clearNotifications.toString()]: (state: NotificationState) => ({
      ...state,
      data: [],
    }),
  },
  initialState
);

const compareNotifications = (n1: Notificacao, n2: Notificacao, notificationType: string): boolean => {
  const mesmoUid = n1.codigo === n2.codigo;
  const mesmaMensagem = n1.evento === n2.evento && n1.status === n2.status;
  const isPendentes = notificationType.indexOf('Pendente') >= 0;
  let result = n1.idUsuario === n2.idUsuario;

  if (isPendentes) {
    result = result && mesmaMensagem;
  } else {
    result = result && mesmoUid;
  }
  return result;
};

const indexOfNotifications = (collection: Notificacao[], value: Notificacao, notificationType: string, closure: any): number => {
  let count = -1;
  for (const element of collection) {
    count++;
    if (closure(value, element, notificationType)) {
      return count;
    }
  }
  return -1;
};
