import ReactHtmlParser from 'react-html-parser';
import { alertManager, AlertType, ERROR, isEmpty, showToast } from 'summer';
import logoBox from '../assets/calima_box_icon.svg';
import logoConnect from '../assets/calima_connect_icon.svg';
import logoGer from '../assets/calima_ger_icon.svg';
import logoMcg from '../assets/calima_mcg_icon.svg';
import logoMcp from '../assets/calima_mcp_icon.svg';
import logoMfp from '../assets/calima_mfp_icon.svg';
import logoMho from '../assets/calima_mho_icon.svg';
import logoMic from '../assets/calima_mic_icon.svg';
import logoMlf from '../assets/calima_mlf_icon.svg';
import logoMpr from '../assets/calima_mpr_icon.svg';
import logoSite from '../assets/calima_site_icon.svg';
import { GlobalParameter } from '../models/dtos/ger/globalParameter';
import { MenuItem } from '../models/dtos/ger/menuItem';
import { gerarUrlAcessoTicket } from '../services/ger.service';
import boxMenu from '../views/pages/box/boxMenu';
import boxRoutes from '../views/pages/box/boxRoutes';
import connectMenu from '../views/pages/connect/connectMenu';
import connectRoutes from '../views/pages/connect/connectRoutes';
import defaultRoutes from '../views/pages/defaultRoutes';
import gerMenu from '../views/pages/ger/gerMenu';
import gerRoutes from '../views/pages/ger/gerRoutes';
import mcgMenu from '../views/pages/mcg/mcgMenu';
import mcgRoutes from '../views/pages/mcg/mcgRoutes';
import mcpMenu from '../views/pages/mcp/mcpMenu';
import mcpRoutes from '../views/pages/mcp/mcpRoutes';
import mfpMenu from '../views/pages/mfp/mfpMenu';
import mfpRoutes from '../views/pages/mfp/mfpRoutes';
import mhoMenu from '../views/pages/mho/mhoMenu';
import mhoMenuNitrus from '../views/pages/mho/mhoMenuNitrus';
import mhoRoutes from '../views/pages/mho/mhoRoutes';
import micMenu from '../views/pages/mic/micMenu';
import micRoutes from '../views/pages/mic/micRoutes';
import mlfMenu from '../views/pages/mlf/mlfMenu';
import mlfRoutes from '../views/pages/mlf/mlfRoutes';
import mprMenu from '../views/pages/mpr/mprMenu';
import mprRoutes from '../views/pages/mpr/mprRoutes';
import siteMenu from '../views/pages/site/siteMenu';
import siteRoutes from '../views/pages/site/siteRoutes';
import { MODULO_CRIACAO_TICKET, MODULO_GERAL_TICKET, SETOR_TICKET, TICKET_MODULO, URL_ATENDIMENTO_MINHA_CONTA } from './wsUtil';

export const MODULOS = [
  {
    logo: logoGer,
    menu: gerMenu,
    name: 'Módulo Principal',
    state: 'GER',
  },
  {
    logo: logoMcg,
    menu: mcgMenu,
    name: 'Módulo Contábil',
    state: 'MCG',
  },
  {
    logo: logoMcp,
    menu: mcpMenu,
    name: 'Módulo Controle Patrimonial',
    state: 'MCP',
  },
  {
    logo: logoMfp,
    menu: mfpMenu,
    name: 'Módulo Folha de Pagamento',
    state: 'MFP',
  },
  {
    logo: logoMho,
    menu: mhoMenu,
    name: 'Módulo de Honorários',
    state: 'MHO',
  },
  {
    logo: logoMlf,
    menu: mlfMenu,
    name: 'Módulo Fiscal',
    state: 'MLF',
  },
  {
    logo: logoMpr,
    menu: mprMenu,
    name: 'Módulo de Protocolos',
    state: 'MPR',
  },
  {
    logo: logoBox,
    menu: boxMenu,
    name: 'Box',
    state: 'BOX',
  },
  {
    logo: logoConnect,
    menu: connectMenu,
    name: 'Connect',
    state: 'CONNECT',
  },
  {
    logo: logoSite,
    menu: siteMenu,
    name: 'Site',
    state: 'SITE',
  },
  {
    logo: logoMic,
    menu: micMenu,
    name: 'Integra Contador',
    state: 'MIC',
  },
];

export const ORDEM_MODULOS = ['GER', 'MFP', 'MCG', 'MLF', 'MCP', 'MHO', 'MPR', 'CONNECT', 'BOX', 'SITE', 'MIC'];

export const abrirSuporteTicket = (currentState, globalParameter, loading, crispAction) => {
  const params = { chave: SETOR_TICKET, valor: TICKET_MODULO[currentState] || MODULO_GERAL_TICKET };
  abrirSuporteTicketController(params, MODULO_CRIACAO_TICKET, globalParameter, loading, crispAction);
};

export const abrirSuporteTicketController = (setor: any, moduloTicket: string, globalParameter, loading, crispAction) => {
  if (possuiSuporteTicket(globalParameter, crispAction)) {
    loading.show();
    const loginUsuario = globalParameter.usuario.usuario;
    gerarUrlAcessoTicket(
      {
        identificador: null,
        loginUsuario,
        moduloTicket,
        setor,
      },
      {
        errorFunction: message => {
          loading.hide();
          showToast(message, ERROR);
        },
        thenFunction: (res: any) => {
          loading.hide();
          window.open(res.url, '_blank');
        },
      }
    );
  }
};

export const possuiSuporteTicket = (globalParameter, crispAction) => {
  let result = true;
  if (!globalParameter.acesso.suporteTicket) {
    result = false;
    alertManager.emit({
      message: "O serviço 'SUPORTE VIA TICKET' não está habilitado. Deseja adquiri-lo?",
      onOkClick: crispAction.openComercial,
      type: AlertType.WARNING_YES_NO,
    });
  }
  return result;
};

export const openMD5 = (url, globalParameter) => {
  const codigo = globalParameter.usuario.usuario.replace(/\D/g, '');
  const hashUsuario = globalParameter.usuario.md5;
  window.open(`${url}${codigo}/${hashUsuario}`, '_blank');
};

export const abrirMinhaConta = globalParameter => {
  if (globalParameter.empresa && (globalParameter.usuario.administrador || globalParameter.usuario.acessoFinanceiro)) {
    openMD5(URL_ATENDIMENTO_MINHA_CONTA, globalParameter);
  } else {
    alertManager.emit({
      message: 'Seu usuário não possui permissão para acessar essa opção do Sistema. Contate o administrador do sistema.',
      type: AlertType.INFORMATION,
    });
  }
};

export const getSubMenuItem = (modulo, item) => {
  const find = (menu, value, upMenuLabel = '') => {
    let result = null;

    menu.forEach(it => {
      if (!result) {
        if (it.content !== undefined && it.content.length > 0) {
          result = find(it.content, value, it.label);
        } else {
          if (it.to === value) {
            result = {
              ...it,
              label: ReactHtmlParser(isEmpty(upMenuLabel) ? it.label : `<span class="text-black-50">${upMenuLabel}</span> > ${it.label}`),
            };
          }
        }
      }
    });

    return result;
  };

  return find(modulo.menu, `/${item.modulo.toLowerCase()}/${item.itemMenu}`);
};

export const getMenuItem = (item, mhoAsNitrus: boolean = false) => {
  const modulos = [...MODULOS];

  if (mhoAsNitrus) {
    const indexMHO = modulos.findIndex(it => it.state === 'MHO');
    modulos.splice(indexMHO, 1, { ...MODULOS[indexMHO], menu: mhoMenuNitrus, name: 'Nitrus/Honorários' });
  }

  const modulo = modulos.find(it => it.state === item.modulo);

  let result = null;
  if (modulo) {
    result = { modulo: item.modulo, titulo: modulo.name, itens: item.itens.map(it => getSubMenuItem(modulo, it)).filter(it => !!it) };
  }
  return result;
};

export const ordenarModulos = lista => {
  const modulos = [...lista];
  modulos.sort((a, b) => {
    const valA = ORDEM_MODULOS.indexOf(a.modulo);
    const valB = ORDEM_MODULOS.indexOf(b.modulo);
    let result = 0;
    if (valA !== valB) {
      result = valA > valB ? 1 : -1;
    }
    return result;
  });
  return modulos;
};

export const scrollTo = (element, value, speed = 250) =>
  new Promise(resolve => {
    let newValue;
    let oldTimestamp;
    let oldValue;

    let scrollCount = 0;

    oldTimestamp = performance.now();
    oldValue = element.scrollTop;

    const cosParameter = (oldValue - value) / 2;

    const step = newTimestamp => {
      scrollCount += Math.PI / (speed / (newTimestamp - oldTimestamp));
      newValue = Math.round(value + cosParameter + cosParameter * Math.cos(scrollCount));

      if (element.scrollTop === oldValue && !isNaN(scrollCount) && !isNaN(newValue)) {
        if (scrollCount >= Math.PI) {
          scrollTo(element, value, 0);
        } else {
          oldValue = newValue || 0;
          oldTimestamp = newTimestamp;

          element.scrollTop = oldValue;

          window.requestAnimationFrame(step);
        }
      } else {
        resolve(newValue);
      }
    };

    window.requestAnimationFrame(step);
  });

export const getMenuPlainList = state => {
  const getPlain = list => {
    const result = [];

    list.forEach(it => {
      if (it.to) {
        result.push(it);
      } else if (it.content && it.content.length) {
        result.push(...getPlain(it.content));
      }
    });

    return result;
  };

  const modulo = MODULOS.find(it => it.state === state);
  return modulo ? getPlain(modulo.menu) : [];
};

export const getReportList = state => getMenuPlainList(state).filter(it => it.to.indexOf('ReportView') !== -1);

export const getMenu = state => MODULOS.find(it => it.state === state);

export const abrirSiteBackup = globalParameter => {
  if (globalParameter.usuario.administrador) {
    alertManager.emit({
      message:
        'A gerência de seu Backup está disponível na Central de Cliente. Uma página será aberta no Site da Central de Cliente. Acesse o aplicativo com o seu usuário administrador e vá no menu Principal => Gerência => Meus Backups.',
      onOkClick: () => openMD5(URL_ATENDIMENTO_MINHA_CONTA, globalParameter),
      type: AlertType.INFORMATION,
    });
  } else {
    alertManager.emit({
      message: 'Seu usuário não possui permissão para acessar essa opção do Sistema. Contate o administrador do sistema.',
      type: AlertType.INFORMATION,
    });
  }
};

export const getRoutes = (globalParameter: GlobalParameter) => {
  const { administrador, acessoModulos } = globalParameter.usuario;
  const routes = [...defaultRoutes];

  if (administrador || acessoModulos.ger) {
    routes.push(...gerRoutes);
  }

  if (administrador || acessoModulos.mcg) {
    routes.push(...mcgRoutes);
  }

  if (administrador || acessoModulos.mcp) {
    routes.push(...mcpRoutes);
  }

  if (administrador || acessoModulos.mfp) {
    routes.push(...mfpRoutes);
  }

  if (administrador || acessoModulos.mho) {
    routes.push(...mhoRoutes);
  }

  if (administrador || acessoModulos.mlf) {
    routes.push(...mlfRoutes);
  }

  if (administrador || acessoModulos.mpr) {
    routes.push(...mprRoutes);
  }

  if (administrador || acessoModulos.box) {
    routes.push(...boxRoutes);
  }

  if (administrador || acessoModulos.connect) {
    routes.push(...connectRoutes);
  }

  if (administrador || acessoModulos.site) {
    routes.push(...siteRoutes);
  }

  if (administrador || acessoModulos.mic) {
    routes.push(...micRoutes);
  }

  return routes.filter(it => validarTelasSemAcesso(globalParameter, it.path));
};

export const validarTelasSemAcesso = (globalParameter: GlobalParameter, linkTela: string) =>
  !globalParameter.usuario.telasSemAcesso.map(tela => tela.linkTela).includes((linkTela || '').replace('/edit', ''));

export const podeAcessarItemMenu = (menu: MenuItem, globalParameter: GlobalParameter, currentState: string) =>
  validarTelasSemAcesso(globalParameter, menu.to || `/${currentState.toLowerCase()}/${menu.label}`) &&
  (!menu.isVisible || menu.isVisible(globalParameter));

export const filterVisibles = (menu: MenuItem, globalParameter: GlobalParameter, currentState: string) => {
  return menu.content
    ? menu.content.filter(it => filterVisibles(it, globalParameter, currentState)).length > 0
    : podeAcessarItemMenu(menu, globalParameter, currentState);
};
