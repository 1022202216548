import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import {
  alertManager,
  AlertType,
  CALIMA_DANGER,
  CALIMA_ORANGE,
  CALIMA_SUCCESS,
  CALIMA_WARNING,
  ColumnsProps,
  dec,
  DefaultSortProps,
  ERROR,
  INFORMATION,
  OrderType,
  SearchDataGrid,
  showToast,
} from 'summer';
import { GlobalParameter } from '../../../models/dtos/ger/globalParameter';
import { AGUARDANDO, AVISO, CANCELADO, ERRO, FINALIZADO, JOB_STATUS_ENUM_VALUES } from '../../../models/enumerated/ger/jobStatusEnum';
import { cancelJobs, findAllJobs, removeFinishedJobs } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { fecharJanelaProcessos } from '../../../store/ducks/global.duck';
import EnumUtil from '../../../utilities/enum.util';
import './JobInfoView.css';

const defaultSorted: DefaultSortProps[] = [
  {
    dataField: 'mensagem',
    order: OrderType.ASC,
  },
];

const LABEL_COLOR = {
  [FINALIZADO.value]: CALIMA_SUCCESS,
  [ERRO.value]: CALIMA_DANGER,
  [AGUARDANDO.value]: CALIMA_WARNING,
  [AVISO.value]: CALIMA_ORANGE,
  [CANCELADO.value]: CALIMA_DANGER,
};

const JobInfoView: FC = () => {
  const [listaJobs, setListaJobs] = useState([]);
  const { usuario } = useSelector<Reducers, GlobalParameter>(state => state.globalReducer.globalParameter);

  const {
    jobReducer: { data },
    globalReducer: { exibirJanelaProcessos },
  } = useSelector<Reducers, Reducers>(state => state);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllJobs();
  }, [data]);

  const getAllJobs = () => {
    findAllJobs({
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: result => setListaJobs(result),
    });
  };

  const removeJobs = () => {
    removeFinishedJobs({
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: () => getAllJobs(),
    });
  };

  const toggle = () => dispatch(fecharJanelaProcessos());

  const cancelar = id => {
    cancelJobs([id], {
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: () => null,
    });
  };

  const cancelarTodosProcessos = () => {
    if (listaJobs.length > 0 && listaJobs.find(it => it && it.status === AGUARDANDO.value)) {
      alertManager.emit({
        message: `Deseja cancelar todos os processos que estão aguardando?`,
        onOkClick: () => {
          cancelJobs(
            listaJobs.map(it => it.id),
            {
              errorFunction: mensagem => showToast(mensagem, ERROR),
              thenFunction: () => null,
            }
          );
        },
        type: AlertType.WARNING_YES_NO,
      });
    } else {
      showToast('Não existe processo para ser cancelado.', INFORMATION);
    }
  };

  const columns: ColumnsProps[] = [
    {
      dataField: 'mensagem',
      sort: true,
      text: 'Processo',
    },
    {
      dataField: 'createdBy',
      sort: true,
      text: 'Criado por',
    },
    {
      dataField: 'status',
      formatter: (status, { cancelledBy }) =>
        status === CANCELADO.value ? 'Cancelado por ' + cancelledBy : EnumUtil.find(JOB_STATUS_ENUM_VALUES, status).label,

      style: (_, row) => ({ color: (row && LABEL_COLOR[row.status]) || '#33aff2' }),
      text: 'Situação',
    },
    {
      dataField: 'dtInicio',
      sort: true,
      text: 'Início do processo',
    },
    {
      dataField: 'dtInicioExecucao',
      sort: true,
      text: 'Data início da execução',
    },
    {
      dataField: 'dtFim',
      sort: true,
      text: 'Data fim do processo',
    },
    {
      dataField: 'duracao',
      sort: true,
      text: 'Duração do processo',
    },
    {
      dataField: 'duracaoExecucao',
      sort: true,
      text: 'Duração da execução processo',
    },
    {
      dataField: 'acoes',
      formatter: (_, row) => {
        const id = `cancelar-exec-${row.id}`;
        return (
          row.status === AGUARDANDO.value && (
            <>
              <button className="cancelar-exec" id={id} onClick={cancelar.bind(null, row.id)}>
                Cancelar execução
                <FontAwesomeIcon icon="times" />
              </button>
              <UncontrolledTooltip target={id}>Remover este processo da fila de execução.</UncontrolledTooltip>
            </>
          )
        );
      },
      text: 'Ações',
    },
  ];

  return (
    <Modal isOpen={exibirJanelaProcessos} toggle={toggle} size="xl" centered={true}>
      <ModalHeader className="jobinfo-header py-2" toggle={toggle}>
        Utilitários > Processo
      </ModalHeader>
      <ModalBody>
        <SearchDataGrid
          columns={columns}
          defaultSorted={defaultSorted}
          data={listaJobs}
          disableDoubleClick={true}
          hideActionBar={true}
          extraData={{ hideActionColumn: true }}
        />
      </ModalBody>
      <ModalFooter>
        <div className="left-buttons mb-0 mr-auto">
          <Button size="sm" onClick={cancelarTodosProcessos} color="link" className="mr-4 cancelar-todos mb-2">
            <span>Cancelar processos em fila</span>
          </Button>
          <Button size="sm" onClick={removeJobs} color="danger" className="mb-2">
            <span>Retirar processos finalizados da lista</span>
          </Button>
        </div>
        <Button size="sm" onClick={getAllJobs} color="first" className="mb-2 mr-2">
          <span>Atualizar</span>
          <FontAwesomeIcon icon="sync" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default JobInfoView;
