/** controller */
export const URL_CONTROLLER_PROPAGANDA = 'https://login-api-controller.projetusti.com.br/marketing/anuncio-react?codigo=';

/** Projetus */
export const URL_BOX = 'https://www.calimaerp.com/calimabox';
export const URL_CONNECT = 'https://connect.calima.app';
export const URL_SITE = 'https://site.projetusti.com.br';
export const URL_NITRUS = 'https://www.nitrus.com.br/';
export const URL_PROJETUS = 'https://www.projetusti.com.br';
export const URL_PROJETUS_VIDEOS = 'https://www.youtube.com/user/ProjetusTI';
export const URL_PROJETUS_REMOTO_TV = 'https://get.teamviewer.com/projetusti';
export const URL_VENDA_SITE = 'https://www.calimaerp.com/calimasite';
export const URL_VENDA_CONNECT = 'https://www.calimaerp.com/calimaconnect';
export const URL_VENDA_MIC = 'https://www.calimaerp.com/integracontador';

/** Atendimento */
export const URL_ATENDIMENTO_LOJA = 'https://atendimento.projetusti.com.br/chat/#/0f01ea6c-dbd3-42f4-9543-c951702ab3de/';
export const URL_AJUDA_MFP927 = 'https://ajuda.calimaerp.com/pt/article/calculo-de-13-mpv-9272020-l652eg/?1608299343168';
export const URL_AJUDA_DCTFWEB = 'https://ajuda.calima.app/index.php?title=Gera%C3%A7%C3%A3o_do_DCTFWeb';

/** Atendimento Crisp */
export const ID_ATENDIMENTO_GER = '0b82f424-eb78-4211-b164-c4495cb90e44';
export const ID_ATENDIMENTO_MCGMCP = '26b1d85d-019f-4149-8041-b7e108c971cb';
export const ID_ATENDIMENTO_MFP = 'f62b38b9-3585-4cfd-8c3f-99d075f465a2';
export const ID_ATENDIMENTO_MLF = '1f037e1b-662e-4181-9375-7a04c6483cd0';
export const ID_ATENDIMENTO_MHOMPR = '8de9f696-0d53-44f1-b029-5c42f103e054';
export const ID_ATENDIMENTO_MFP_ESOCIAL = '7b5dd7da-ef01-41fe-8005-4fa3d7cb88ab';
export const ID_ATENDIMENTO_COMERCIAL = '0f01ea6c-dbd3-42f4-9543-c951702ab3de';
export const ID_ATENDIMENTO_CONNECT_BOX_SITE = '59fe9ca8-d131-44f8-9860-fd0c0c0dcd48';
export const ID_ATENDIMENTO_MODULO = {
  BOX: ID_ATENDIMENTO_CONNECT_BOX_SITE,
  CONNECT: ID_ATENDIMENTO_CONNECT_BOX_SITE,
  GER: ID_ATENDIMENTO_GER,
  MCG: ID_ATENDIMENTO_MCGMCP,
  MCP: ID_ATENDIMENTO_MCGMCP,
  MHO: ID_ATENDIMENTO_MHOMPR,
  MLF: ID_ATENDIMENTO_MLF,
  MPR: ID_ATENDIMENTO_MHOMPR,
  SITE: ID_ATENDIMENTO_CONNECT_BOX_SITE,
};

/** URLs menu Ajuda */
export const URL_FORUMCALIMA = 'https://forum.calimaerp.com';
export const URL_PROACADEMY = 'https://www.calimaerp.com/proacademy-calima5#Comecar5';
export const URL_CALIMA = 'https://www.calimaerp.com/';
export const URL_ATENDIMENTO_MINHA_CONTA = 'https://centraldocliente.projetusti.com.br/#/acessar/dashboard/';
export const URL_TREINAMENTO = 'https://www.calimaerp.com/treinamentos';
export const URL_PROJETUS_REMOTO = 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/id3qvymhlwic/b/downloads/o/ProjetusRemoto.exe';
export const URL_AJUDA_CALIMA = 'https://ajuda.calimaerp.com';
export const VIDEO_AULA_MODULO = {
  GER: 76,
  MCG: 82,
  MCP: 98,
  MFP: 126,
  MHO: 147,
  MLF: 107,
  MPR: 160,
};

/*
 * Ticket
 */
export const SETOR_TICKET = 'setor';
export const MODULO_CRIACAO_TICKET = 'criar-ticket';
export const MODULO_CONSULTA_TICKET = 'acessar-tickets';
export const MODULO_CONTABIL_TICKET = 'contabilidade';
export const MODULO_FOLHA_TICKET = 'folha-pagamento';
export const MODULO_FISCAL_TICKET = 'fiscal';
export const MODULO_GERAL_TICKET = 'geral';
export const TICKET_MODULO = {
  MCG: MODULO_CONTABIL_TICKET,
  MFP: MODULO_FOLHA_TICKET,
  MLF: MODULO_FISCAL_TICKET,
};

export const URL_RETROSPECTIVA = 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/id3qvymhlwic/b/retrospectiva/o/2022/index.html';
export const URL_RETROSPECTIVA_BANNER = 'https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/id3qvymhlwic/b/retrospectiva/o/2022/assets/call.png';
