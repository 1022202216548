import { GlobalParameter } from '../models/dtos/ger/globalParameter';

export const atualizarEmpresaGlobalParameter = (globalParameter, dadosEmpresaGlobalParameter): GlobalParameter => ({
  ...globalParameter,
  empresa: dadosEmpresaGlobalParameter.empresa,
  empresaESocialConfig: dadosEmpresaGlobalParameter.empresaEsocialConfig,
  empresaMCG: dadosEmpresaGlobalParameter.empresa.empresaMCG,
  empresaMCP: dadosEmpresaGlobalParameter.empresa.empresaMCP,
  empresaMFP: dadosEmpresaGlobalParameter.empresa.empresaMFP,
  empresaMLF: dadosEmpresaGlobalParameter.empresa.empresaMLF,
  grupoEmpresaConfig: dadosEmpresaGlobalParameter.empresa.grupoEmpresaRaiz.grupoEmpresaConfig,
  grupoForcli: dadosEmpresaGlobalParameter.empresa.grupoEmpresa.grupoEmpresaConfig.grupoForcli,
  grupoHistorico: dadosEmpresaGlobalParameter.empresa.grupoEmpresa.grupoEmpresaConfig.grupoHistorico,
  grupoPlanoConta: dadosEmpresaGlobalParameter.empresa.grupoEmpresa.grupoEmpresaConfig.grupoPlanoConta,
  lancamentoMCGConfig: dadosEmpresaGlobalParameter.empresa.empresaMCG.configLancamento,
});
