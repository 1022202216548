import Axios, { AxiosInstance } from 'axios';
import { AxiosParam, Basic, create, send } from 'summer';
import { ImportacaoParam } from '../models/dtos/ger/importacaoParam';
import { RuleMessage } from '../models/dtos/ger/ruleMessage';
import { Usuario } from '../models/dtos/ger/usuario';
import { UsuarioHistAcesso } from '../models/dtos/ger/usuarioHistAcesso';
import { AgendamentoEnvioAutomaticoESocialParam } from '../models/dtos/mfp/agendamentoEnvioAutomaticoESocialParam';
import { ArquivoImportadoDOS } from '../models/dtos/mfp/arquivoImportadoDOS';
import { ArquivoImportadoNis } from '../models/dtos/mfp/arquivoImportadoNis';
import { ArquivoImportadoPontoEletronico } from '../models/dtos/mfp/arquivoImportadoPontoEletronico';
import { BasicESocial } from '../models/dtos/mfp/basicEsocial';
import { BEMExportacaoParam } from '../models/dtos/mfp/bemExportacaoParam';
import { BolsaQualificacaoExportacaoParam } from '../models/dtos/mfp/bolsaQualificacaoExportacaoParam';
import { CagedParam } from '../models/dtos/mfp/cagedParam';
import { CalculoParam } from '../models/dtos/mfp/calculoParam';
import { CalculoProvisaoParam } from '../models/dtos/mfp/calculoProvisaoParam';
import { CalculoReferenciaExample } from '../models/dtos/mfp/calculoReferenciaExample';
import { CalculosVinculo } from '../models/dtos/mfp/calculosVinculo';
import { Cat } from '../models/dtos/mfp/cat';
import { ComparativoMemoriaCalculoIRRFVinculoVO } from '../models/dtos/mfp/comparativoMemoriaCalculoIRRFVinculoVO';
import { CondicaoAmbientalTrabalho } from '../models/dtos/mfp/condicaoAmbientalTrabalho';
import { ConfiguracaoContraChequeEletronicoAdvertencia } from '../models/dtos/mfp/configuracaoContraChequeEletronicoAdvertencia';
import { ConsultaCalculosParam } from '../models/dtos/mfp/consultaCalculosParam';
import { ContabilizacaoEventoMFP } from '../models/dtos/mfp/contabilizacaoEventoMFP';
import { ContraChequeEletronicoParam } from '../models/dtos/mfp/contraChequeEletronicoParam';
import { DashboardParam } from '../models/dtos/mfp/dashboardParam';
import { DashboardResultParam } from '../models/dtos/mfp/dashboardResultParam';
import { Dependente } from '../models/dtos/mfp/dependente';
import { DiaUtil } from '../models/dtos/mfp/diaUtil';
import { DirfParam } from '../models/dtos/mfp/dirfParam';
import { EmpresaESocialConfig } from '../models/dtos/mfp/empresaESocialConfig';
import { EmpresaMFP } from '../models/dtos/mfp/empresaMFP';
import { EnvioAutomaticoESocialParam } from '../models/dtos/mfp/envioAutomaticoESocialParam';
import { EsocialEvento } from '../models/dtos/mfp/esocialEvento';
import { ESocialEventoPendente } from '../models/dtos/mfp/eSocialEventoPendente';
import { EsocialParam } from '../models/dtos/mfp/esocialParam';
import { EsocialSemaforoStatusParam } from '../models/dtos/mfp/esocialSemaforoStatusParam';
import { Evento } from '../models/dtos/mfp/evento';
import { EventoComConstantesParam } from '../models/dtos/mfp/eventoComConstantesParam';
import { EventoExample } from '../models/dtos/mfp/eventoExample';
import { EventoResilicao } from '../models/dtos/mfp/eventoResilicao';
import { EventoResultEsocial } from '../models/dtos/mfp/eventoResultEsocial';
import { EventosPendentesEnvioAutomaticoESocialParam } from '../models/dtos/mfp/eventosPendentesEnvioAutomaticoESocialParam';
import { EventoTreeResultEsocial } from '../models/dtos/mfp/eventoTreeResultEsocial';
import { ExameToxicologico } from '../models/dtos/mfp/exameToxicologico';
import { ExclusaoCalculosParam } from '../models/dtos/mfp/exclusaoCalculosParam';
import { ExisteCalculoVinculoTomador } from '../models/dtos/mfp/existeCalculoVinculoTomador';
import { ExportacaoEventoSimplificadoParam } from '../models/dtos/mfp/exportacaoEventoSimplificadoParam';
import { FechamentoRestauracaoMFP } from '../models/dtos/mfp/fechamentoRestauracaoMFP';
import { Ferias } from '../models/dtos/mfp/ferias';
import { FeriasResilicao } from '../models/dtos/mfp/feriasResilicao';
import { GeracaoEventoPendenteParam } from '../models/dtos/mfp/geracaoEventoPendenteParam';
import { GrrfParam } from '../models/dtos/mfp/grrfParam';
import { GrupoEvento } from '../models/dtos/mfp/grupoEvento';
import { ImportacaoDOSParam } from '../models/dtos/mfp/importacaoDOSParam';
import { ImportacaoEventoSimplificadoParam } from '../models/dtos/mfp/importacaoEventoSimplificadoParam';
import { ImportacaoPontoEletronicoParam } from '../models/dtos/mfp/importacaoPontoEletronicoParam';
import { ImportacaoVinculoParam } from '../models/dtos/mfp/importacaoVinculoParam';
import { ImportacaoXMLEventosEsocialParam } from '../models/dtos/mfp/importacaoXMLEventosEsocialParam';
import { ImportarIntegracaoFolhaContabilParam } from '../models/dtos/mfp/importarIntegracaoFolhaContabilParam';
import { InfoConsultaProtocolosAutomatica } from '../models/dtos/mfp/infoConsultaProtocolosAutomatica';
import { InformacaoAssinadorA3 } from '../models/dtos/mfp/informacaoAssinadorA3';
import { InformacaoLote } from '../models/dtos/mfp/InformacaoLote';
import { IntegracaoGrupoEventoDTO } from '../models/dtos/mfp/integracaoGrupoEventoDTO';
import { ManadParam } from '../models/dtos/mfp/manadParam';
import { MemoriaCalculoINSSVinculo } from '../models/dtos/mfp/memoriaCalculoINSSVinculo';
import { MemoriaCalculoParam } from '../models/dtos/mfp/memoriaCalculoParam';
import { MonitorEventoEsocialParam } from '../models/dtos/mfp/monitorEventoEsocialParam';
import { MonitorEventoEsocialResult } from '../models/dtos/mfp/monitorEventoEsocialResult';
import { MonitorEventoFiltro } from '../models/dtos/mfp/monitorEventoFiltro';
import { NisParam } from '../models/dtos/mfp/nisParam';
import { PainelControleParam } from '../models/dtos/mfp/painelControleParam';
import { ParametrosCalculoPelaEmpresa } from '../models/dtos/mfp/parametrosCalculoPelaEmpresa';
import { PendenciasCalculo } from '../models/dtos/mfp/pendenciasCalculo';
import { PlanoSaudeDependentesParam } from '../models/dtos/mfp/planoSaudeDependentesParam';
import { PlanoSaudeDependentesValoresParam } from '../models/dtos/mfp/planoSaudeDependentesValoresParam';
import { PlanoSaudeLoteParam } from '../models/dtos/mfp/planoSaudeLoteParam';
import { ProcessoTrabalhista } from '../models/dtos/mfp/processoTrabalhista';
import { ProtocoloEsocial } from '../models/dtos/mfp/protocoloEsocial';
import { QuadroHorario } from '../models/dtos/mfp/quadroHorario';
import { QuadroHorarioDia } from '../models/dtos/mfp/quadroHorarioDia';
import { QuadroHorarioParam } from '../models/dtos/mfp/quadroHorarioParam';
import { RaisParam } from '../models/dtos/mfp/raisParam';
import { ReintegracaoVinculo } from '../models/dtos/mfp/reintegracaoVinculo';
import { ReplicarNotificacaoParam } from '../models/dtos/mfp/replicarNotificacaoParam';
import { Rescisao } from '../models/dtos/mfp/rescisao';
import { Resilicao } from '../models/dtos/mfp/resilicao';
import { ResultConsultaProtocoloEsocial } from '../models/dtos/mfp/resultConsultaProtocoloEsocial';
import { SalvarPlanoSaudeSimplificadoParam } from '../models/dtos/mfp/salvarPlanoSaudeSimplificadoParam';
import { SefipParam } from '../models/dtos/mfp/sefipParam';
import { SeguroDesempregoParam } from '../models/dtos/mfp/seguroDesempregoParam';
import { SimulacaoRescisao } from '../models/dtos/mfp/simulacaoRescisao';
import { SincronizacaoEsocialBxParam } from '../models/dtos/mfp/sincronizacaoEsocialBxParam';
import { SincronizacaoEventoEsocial } from '../models/dtos/mfp/sincronizacaoEventoEsocial';
import { SituacaoAfastamento } from '../models/dtos/mfp/situacaoAfastamento';
import { SituacaoEventosEsocial } from '../models/dtos/mfp/situacaoEventosEsocial';
import { Trabalhador } from '../models/dtos/mfp/trabalhador';
import { TrabalhadorCargoAtivoParam } from '../models/dtos/mfp/trabalhadorCargoAtivoParam';
import { TrabalhadorQualificacaoCadastral } from '../models/dtos/mfp/trabalhadorQualificacaoCadastral';
import { TrabalhadorQualificacaoCadastralExpoParam } from '../models/dtos/mfp/trabalhadorQualificacaoCadastralExpoParam';
import { TransferenciaVinculo } from '../models/dtos/mfp/transferenciaVinculo';
import { TributosProcessoTrabalhista } from '../models/dtos/mfp/tributosProcessoTrabalhista';
import { ValidacaoFaseEsocial } from '../models/dtos/mfp/validacaoFaseEnvioEsocial';
import { ValidaEscalaParam } from '../models/dtos/mfp/validaEscalaParam';
import { ValorImposto } from '../models/dtos/mfp/valorImposto';
import { Vinculo } from '../models/dtos/mfp/vinculo';
import { VinculoEvento } from '../models/dtos/mfp/vinculoEvento';
import { VinculoEventoPlanoSaudeBuscaParam } from '../models/dtos/mfp/vinculoEventoPlanoSaudeBuscaParam';
import { VinculoEventoPlanoSaudeFiltroParam } from '../models/dtos/mfp/vinculoEventoPlanoSaudeFiltroParam';
import { VinculoEventoPlanoSaudeResultParam } from '../models/dtos/mfp/vinculoEventoPlanoSaudeResultParam';
import { VinculoExameOcupacional } from '../models/dtos/mfp/vinculoExameOcupacional';
import { VinculoMatriculaNome } from '../models/dtos/mfp/vinculoMatriculaNome';
import { VinculoSalario } from '../models/dtos/mfp/vinculoSalario';
import { VinculoTomadorServicoLoteParam } from '../models/dtos/mfp/vinculoTomadorServicoLoteParam';
import { getHeaders } from './ger.service';

function getService(): AxiosInstance {
  return create('/mfp', false);
}

function getLocalhostService(): AxiosInstance {
  return Axios.create({
    baseURL: 'http://localhost:10337',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  });
}

const empresaId = 'empresa.id';
const usuarioId = 'usuario.id';

export function copiarValorImpostoAnteriorAReferencia(reference: string, param: AxiosParam<ValorImposto>) {
  send(getService().get<ValorImposto>(`/copiarValorImpostoAnteriorAReferencia/${reference}`), param);
}

export function copiarValorImpostoAnteriorAsReferencias(refs: string[], param: AxiosParam<ValorImposto[]>) {
  send(getService().post<ValorImposto[]>('/copiarValorImpostoAnteriorAsReferencias', refs), param);
}

export function loadFotoTrabalhador(trabalhador: Trabalhador, param: AxiosParam<Trabalhador>) {
  send(getService().post<Trabalhador>('/loadFotoTrabalhador', trabalhador), param);
}

export function deleteFotoTrabalhador(trabalhador: Trabalhador, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/deleteFotoTrabalhador', trabalhador), param);
}

export function validarSalarioVinculo(vinculo: number, salario: VinculoSalario, param: AxiosParam<boolean>) {
  send(
    getService().post<boolean>(`/validarSalarioVinculo/${vinculo}`, { valor: salario.valor, data: salario.data }),
    param
  );
}

export function validarVinculoBeforePersist(vinculo: Vinculo, param: AxiosParam<any>) {
  send(getService().post('/validarVinculoBeforePersist', vinculo), param);
}

export function verificarSituacaoAfastamento(situacaoAfastamento: SituacaoAfastamento, param: AxiosParam<any>) {
  send(getService().post<any>('/verificarSituacaoAfastamento', situacaoAfastamento), param);
}

export function existeCalculoVinculoTomadorServico(existeCalculoParams: ExisteCalculoVinculoTomador, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/existeCalculoVinculoTomadorServico', existeCalculoParams), param);
}

export function perdeuPeriodoAquisitivoFerias(ferias: Ferias, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/perdeuPeriodoAquisitivoFerias', ferias), param);
}

export function obterDataFinalGozoFerias(ferias: Ferias, param: AxiosParam<string>) {
  send(getService().post<string>('/obterDataFinalGozoFerias', ferias), param);
}

export function ajustarPeriodoAquisitivoFeriasPerdidoPorAfastamentos(ferias: Ferias, param: AxiosParam<any>) {
  send(getService().post('/ajustarPeriodoAquisitivoFeriasPerdidoPorAfastamentos', ferias), param);
}

export function getDiaUtil(diaUtilParams: DiaUtil, param: AxiosParam<string>) {
  send(getService().post<string>('/getDiaUtil', diaUtilParams), param);
}

export function simularFerias(vinculo: Vinculo, param: AxiosParam<string>) {
  send(getService().post<string>('/simularFerias', vinculo), param);
}

export function definirAvisoPrevio(rescisao: Rescisao, param: AxiosParam<Rescisao>) {
  send(getService().post<Rescisao>('/definirAvisoPrevio', rescisao), param);
}

export function obterFeriasRescisao(rescisao: Rescisao, hashId: string, param: AxiosParam<number>) {
  send(getService().post<number>(`/obterFeriasRescisao/${hashId}`, rescisao), param);
}

export function rescisaoCalculada(rescisao: Rescisao, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/rescisaoCalculada', rescisao), param);
}

export function simularRescisao(rescisao: Rescisao, param: AxiosParam<SimulacaoRescisao>) {
  send(getService().post<SimulacaoRescisao>('/simularRescisao', rescisao), param);
}

export function apagarRescisao(idRescisao: number, idVinculo: number, param: AxiosParam<void>) {
  send(getService().delete<void>(`/apagarRescisao/${idRescisao}/${idVinculo}`), param);
}

export function validarEventoVinculo(vinculoEvento: VinculoEvento, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/validarEventoVinculo', vinculoEvento), param);
}

export function transferirVinculo(transferencia: TransferenciaVinculo, param: AxiosParam<number>) {
  send(getService().post<number>('/transferirVinculo', transferencia, getHeaders()), param);
}

export function reintegrarVinculo(reintegracao: ReintegracaoVinculo, param: AxiosParam<string>) {
  send(getService().post<string>('/reintegrarVinculo', reintegracao, getHeaders()), param);
}

export function criarEventosPadroes(grupo: GrupoEvento, param: AxiosParam<void>) {
  send(getService().post<void>('/criarEventosPadroes', grupo), param);
}

export function gerarManad(manadParam: ManadParam, param: AxiosParam<string>) {
  send(getService().post<string>('/gerarManad', manadParam, getHeaders()), param);
}

export function gerarRais(raisParam: RaisParam, param: AxiosParam<string>) {
  send(getService().post<string>('/gerarRais', raisParam, getHeaders()), param);
}

export function gerarDirf(dirfParam: DirfParam, param: AxiosParam<string>) {
  send(getService().post<string>('/gerarDirf', dirfParam, getHeaders()), param);
}

export function gerarCaged(cagedParam: CagedParam, param: AxiosParam<string>) {
  send(getService().post<string>('/gerarCaged', cagedParam, getHeaders()), param);
}

export function vinculosComOutrasRemuneracoesIncidindoINSS(sefipParam: SefipParam, param: AxiosParam<VinculoMatriculaNome[]>) {
  send(getService().post<VinculoMatriculaNome[]>('/vinculosComOutrasRemuneracoesIncidindoINSS', sefipParam, getHeaders()), param);
}

export function gerarSefip(sefipParam: SefipParam, param: AxiosParam<string>) {
  send(getService().post<string>('/gerarSefip', sefipParam, getHeaders()), param);
}

export function gerarNis(nisParam: NisParam, param: AxiosParam<string>) {
  send(getService().post<string>('/gerarNis', nisParam, getHeaders()), param);
}

export function gerarSeguroDesemprego(seguroDesempregoParam: SeguroDesempregoParam, param: AxiosParam<string>) {
  send(getService().post<string>('/gerarSeguroDesemprego', seguroDesempregoParam, getHeaders()), param);
}

export function validarContabilizacaoEvento(contabilizacaoEventoMFP: ContabilizacaoEventoMFP, param: AxiosParam<{ [name: string]: boolean }>) {
  send(getService().post<{ [name: string]: boolean }>('/validarContabilizacaoEvento', contabilizacaoEventoMFP), param);
}

export function importarIntegracaoFolhaContabil(importarFolhaContabilParam: ImportarIntegracaoFolhaContabilParam, param: AxiosParam<string>) {
  send(getService().post<string>('/importarIntegracaoFolhaContabil', importarFolhaContabilParam, getHeaders()), param);
}

export function removerEventoTrabalhador(painelControleParam: PainelControleParam, param: AxiosParam<number>) {
  send(getService().post<number>('/removerEventoTrabalhador', painelControleParam, getHeaders()), param);
}

export function fechamentoRestauracao(fechamentoRestauracaoMFP: FechamentoRestauracaoMFP, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/fechamentoRestauracao', fechamentoRestauracaoMFP), param);
}

export function obterFeriasResilicao(resilicao: Resilicao, param: AxiosParam<FeriasResilicao[]>) {
  send(getService().post<FeriasResilicao[]>('/obterFeriasResilicao', resilicao), param);
}

export function obterEventosResilicao(resilicao: Resilicao, param: AxiosParam<EventoResilicao[]>) {
  send(getService().post<EventoResilicao[]>('/obterEventosResilicao', resilicao), param);
}

export function simularResilicao(resilicao: Resilicao, param: AxiosParam<SimulacaoRescisao>) {
  send(getService().post<SimulacaoRescisao>('/simularResilicao', resilicao), param);
}

export function definirAvisoPrevioResilicao(resilicao: Resilicao, param: AxiosParam<Resilicao>) {
  send(getService().post<Resilicao>('/definirAvisoPrevioResilicao', resilicao), param);
}

export function getUltimasFerias(vinculo: Vinculo, param: AxiosParam<Ferias>) {
  send(getService().post<Ferias>('/getUltimasFerias', vinculo), param);
}

export function getProximaFeriasProgramadas(vinculo: Vinculo, envioEsocial: boolean, param: AxiosParam<Ferias>) {
  send(getService().post<Ferias>(`/getProximaFeriasProgramadas/${envioEsocial}`, vinculo), param);
}

export function validarNis(importacaoParam: ImportacaoParam, param: AxiosParam<ArquivoImportadoNis>) {
  const formDataSend = new FormData();
  formDataSend.append('arquivoSelecionado', importacaoParam.arquivoSelecionado[0].file, importacaoParam.arquivoSelecionado[0].nomeArquivo);
  delete importacaoParam.arquivoSelecionado;
  formDataSend.append('importacaoParam', JSON.stringify(importacaoParam));

  send(getService().post<ArquivoImportadoNis>('/validarNis', formDataSend), param);
}

export function persistNis(arquivoImportadoNis: ArquivoImportadoNis, param: AxiosParam<RuleMessage[]>) {
  send(getService().post<RuleMessage[]>('/persistNis', arquivoImportadoNis), param);
}

export function importarPontoEletronico(
  importacaoPontoEletronicoParam: ImportacaoPontoEletronicoParam,
  param: AxiosParam<ArquivoImportadoPontoEletronico>
) {
  const formDataSend = new FormData();
  formDataSend.append('pontoEletronicoParam', JSON.stringify(importacaoPontoEletronicoParam));

  for (const arquivo of importacaoPontoEletronicoParam.arquivos) {
    formDataSend.append('arquivos', arquivo.file, arquivo.nomeArquivo);
  }

  send(getService().post<ArquivoImportadoPontoEletronico>('/importarPontoEletronico', formDataSend, getHeaders()), param);
}

export function importarVinculo(data: ImportacaoVinculoParam, param: AxiosParam<number>) {
  send(getService().post<number>('/importarVinculo', data, getHeaders()), param);
}

export function persistPontoEletronico(arquivoImportadoPontoEletronico: ArquivoImportadoPontoEletronico, param: AxiosParam<RuleMessage[]>) {
  send(getService().post<RuleMessage[]>('/persistPontoEletronico', arquivoImportadoPontoEletronico, getHeaders()), param);
}

export function importarEmpresaDOS(importacaoDOSParam: ImportacaoDOSParam, param: AxiosParam<ArquivoImportadoDOS>) {
  const formDataSend = new FormData();
  formDataSend.append('importacaoDOSParam', JSON.stringify(importacaoDOSParam));

  for (const arquivo of importacaoDOSParam.arquivos) {
    formDataSend.append('arquivos', arquivo.file, arquivo.nomeArquivo);
  }
  send(getService().post<ArquivoImportadoDOS>('/importarEmpresaDOS', formDataSend, getHeaders()), param);
}

export function persistVinculoDOS(
  arquivoImportadoDOS: ArquivoImportadoDOS,
  importacaoDOSParam: ImportacaoDOSParam,
  param: AxiosParam<ArquivoImportadoDOS>
) {
  const formDataSend = new FormData();
  formDataSend.append('arquivoImportadoDOS', JSON.stringify(arquivoImportadoDOS));
  formDataSend.append(empresaId, String(importacaoDOSParam.empresa.id));
  formDataSend.append(usuarioId, String(importacaoDOSParam.usuario.id));

  for (const arquivo of importacaoDOSParam.arquivos) {
    formDataSend.append('arquivos', arquivo.file, arquivo.nomeArquivo);
  }

  send(getService().post<ArquivoImportadoDOS>('/persistVinculoDOS', formDataSend, getHeaders()), param);
}

export function persistDOS(arquivoImportadoDOS: ArquivoImportadoDOS, importacaoDOSParam: ImportacaoDOSParam, param: AxiosParam<any>) {
  const formDataSend = new FormData();
  formDataSend.append('arquivoImportadoDOS', JSON.stringify(arquivoImportadoDOS));
  formDataSend.append(empresaId, String(importacaoDOSParam.empresa.id));
  formDataSend.append(usuarioId, String(importacaoDOSParam.usuario.id));

  for (const arquivo of importacaoDOSParam.arquivos) {
    formDataSend.append('arquivos', arquivo.file, arquivo.nomeArquivo);
  }

  send(getService().post('/persistDOS', formDataSend, getHeaders()), param);
}

export function obterValorImposto(reference: string, param: AxiosParam<ValorImposto>) {
  send(getService().get<ValorImposto>(`/obterValorImposto/${reference}`), param);
}

export function gerarBEM(bemExportacaoParam: BEMExportacaoParam, usuario: number, param: AxiosParam<RuleMessage[]>) {
  send(getService().post<RuleMessage[]>(`/gerarBEM/${usuario}`, bemExportacaoParam, getHeaders()), param);
}

export function validarContraChequeEletronico(
  contraChequeEletronicoParam: ContraChequeEletronicoParam,
  param: AxiosParam<ConfiguracaoContraChequeEletronicoAdvertencia[]>
) {
  send(getService().post<ConfiguracaoContraChequeEletronicoAdvertencia[]>('/validarContraChequeEletronico', contraChequeEletronicoParam), param);
}

export function gerarContraChequeEletronico(
  contraChequeEletronicoParam: ContraChequeEletronicoParam,
  param: AxiosParam<ConfiguracaoContraChequeEletronicoAdvertencia[]>
) {
  send(
    getService().post<ConfiguracaoContraChequeEletronicoAdvertencia[]>('/gerarContraChequeEletronico', contraChequeEletronicoParam, getHeaders()),
    param
  );
}

export function integrarGrupoEventos(integracaoGrupoEventoDTO: IntegracaoGrupoEventoDTO, param: AxiosParam<number>) {
  send(getService().post<number>('/integrarGrupoEventos', integracaoGrupoEventoDTO, getHeaders()), param);
}

export function ajustarParamsCalculoPelaEmpresa(params: ParametrosCalculoPelaEmpresa, param: AxiosParam<CalculoParam>) {
  send(getService().post<CalculoParam>('/ajustarParamentosCalculoPelaEmpresa', params), param);
}

export function verificarPendeciasCalculo(referencia: string, empresa: EmpresaMFP, param: AxiosParam<PendenciasCalculo>) {
  send(getService().post<PendenciasCalculo>(`/verificarPendeciasCalculo/${referencia}`, empresa), param);
}

export function validarCalculo(calculoParam: CalculoParam, hashId: string, hashIdMsg: string, param: AxiosParam<number>) {
  send(getService().post<number>(`/validarCalculo/${hashId}/${hashIdMsg}`, calculoParam, getHeaders()), param);
}

export function calculoGenerico(calculoParam: CalculoParam, param: AxiosParam<number>) {
  send(getService().post<number>('/calculoGenerico', calculoParam, getHeaders()), param);
}

export function excluirCalculos(exclusaoCalculosParam: ExclusaoCalculosParam[], param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/excluirCalculos', exclusaoCalculosParam), param);
}

export function getVariaveisComLegendaTodosNiveis(eventoComConstantesParam: EventoComConstantesParam, param: AxiosParam<string[]>) {
  send(getService().post<string[]>('/getVariaveisComLegendaTodosNiveis', eventoComConstantesParam), param);
}

export function checarExistenciaOutraResilicaoNaMesmaRef(resilicao: Resilicao, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/checarExistenciaOutraResilicaoNaMesmaRef', resilicao), param);
}

export function findTrabalhadoresQualificacaoByEmpresa(
  trabalhadorQualificacao: TrabalhadorQualificacaoCadastralExpoParam,
  param: AxiosParam<TrabalhadorQualificacaoCadastral[]>
) {
  send(getService().post<TrabalhadorQualificacaoCadastral[]>('/findTrabalhadoresQualificacaoByEmpresa', trabalhadorQualificacao), param);
}

export function gerarQualificacaoTrabalhadorEsocial(trabalhadorQualificacao: TrabalhadorQualificacaoCadastralExpoParam, param: AxiosParam<string>) {
  send(getService().post<string>('/gerarQualificacaoTrabalhadorEsocial', trabalhadorQualificacao, getHeaders()), param);
}

export function validarQualificacaoCadastral(importacaoParam: ImportacaoParam, param: AxiosParam<TrabalhadorQualificacaoCadastral[]>) {
  const formDataSend = new FormData();
  formDataSend.append('idEmpresa', String(importacaoParam.empresa.id));
  formDataSend.append('idUsuario', String(importacaoParam.usuario.id));

  formDataSend.append('arquivoSelecionado', importacaoParam.arquivoSelecionado[0].file, importacaoParam.arquivoSelecionado[0].nomeArquivo);

  send(getService().post<TrabalhadorQualificacaoCadastral[]>('/validarQualificacaoCadastral', formDataSend), param);
}

export function getFormulaFmtCompleta(evento: Evento, param: AxiosParam<any>) {
  send(getService().post('/getFormulaFmtCompleta', evento), param);
}

export function validarFormula(evento: Evento, param: AxiosParam<void>) {
  send(getService().post<void>('/validarFormula', evento), param);
}

export function restaurarEventosPadroes(grupoEvento: GrupoEvento, param: AxiosParam<void>) {
  send(getService().post<void>('/restaurarEventosPadroes', grupoEvento), param);
}

export function getEventosPadroes(param: AxiosParam<Evento[]>) {
  send(getService().get<Evento[]>('/getEventosPadroes'), param);
}

export function getCodigosEventosContenhaEvento(eventoExample: EventoExample, param: AxiosParam<string[]>) {
  send(getService().post<string[]>('/getCodigosEventosContenhaEvento', eventoExample), param);
}

export function consultaCalculos(params: ConsultaCalculosParam, param: AxiosParam<CalculosVinculo>) {
  send(getService().post<CalculosVinculo>('/consultaCalculos', params), param);
}

export function getMemoriaCalculoINSS(params: MemoriaCalculoParam, param: AxiosParam<MemoriaCalculoINSSVinculo>) {
  send(getService().post<MemoriaCalculoINSSVinculo>('/getMemoriaCalculoINSS', params), param);
}

export function findEventosTabelaToSend(esocialParam: EsocialParam, param: AxiosParam<EventoTreeResultEsocial>) {
  send(getService().post<EventoTreeResultEsocial>('/findEventosTabelaToSend', esocialParam), param);
}

export function verificarDadosEvtsTabela(eSocialParam: EsocialParam, param: AxiosParam<EventoResultEsocial>) {
  send(getService().post<EventoResultEsocial>('/verificarDadosEvtsTabela', eSocialParam), param);
}

export function imprimirPendenciasESocial(eSocialParam: EsocialParam, param: AxiosParam<string>) {
  send(getService().post<string>('/imprimirPendenciasESocial', eSocialParam, getHeaders()), param);
}

export function gerarEsocial(eSocialParam: EsocialParam, param: AxiosParam<string>) {
  send(getService().post<string>('/gerarEsocial', eSocialParam, getHeaders()), param);
}

export function gerarEsocialA3(eSocialParam: EsocialParam, param: AxiosParam<InformacaoLote>) {
  send(getService().post<InformacaoLote>('/gerarEsocialA3', eSocialParam), param);
}

export function findEventosPendentesESocial(filtro: MonitorEventoFiltro, param: AxiosParam<MonitorEventoEsocialResult>) {
  send(getService().post<MonitorEventoEsocialResult>('/findEventosPendentesEsocial', filtro), param);
}

export function findEventosESocial(filtro: MonitorEventoFiltro, param: AxiosParam<MonitorEventoEsocialResult>) {
  send(getService().post<MonitorEventoEsocialResult>('/findEventosESocial', filtro), param);
}

export function deleteEventoPendenteESocial(eventosPendentes: ESocialEventoPendente[], param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/deleteEventoPendenteESocial', eventosPendentes), param);
}

export function consultarProtocoloEsocial(protocoloEsocial: ProtocoloEsocial, param: AxiosParam<ResultConsultaProtocoloEsocial>) {
  send(getService().post<ResultConsultaProtocoloEsocial>('/consultarProtocoloEsocial', protocoloEsocial), param);
}

export function deleteEventoESocial(eventos: EsocialEvento[], param: AxiosParam<any>) {
  send(getService().post('/deleteEventoESocial', eventos), param);
}

export function enviarEventoExclusao(monitorEsocialParam: MonitorEventoEsocialParam, param: AxiosParam<any>) {
  send(getService().post('/enviarEventoExclusao', monitorEsocialParam, getHeaders()), param);
}

export function removerEventoEsocialA3(monitorEsocialParam: MonitorEventoEsocialParam, param: AxiosParam<any>) {
  send(getService().post('/removerEventoEsocialA3', monitorEsocialParam, getHeaders()), param);
}

export function validarEventosPendentes(monitorEsocialParam: MonitorEventoEsocialParam, param: AxiosParam<any>) {
  send(getService().post('/validarEventosPendentes', monitorEsocialParam, getHeaders()), param);
}

export function enviarEventosA1(monitorEsocialParam: MonitorEventoEsocialParam, param: AxiosParam<number>) {
  send(getService().post<number>('/enviarEventosA1', monitorEsocialParam, getHeaders()), param);
}

export function enviarEventosA3(monitorEsocialParam: MonitorEventoEsocialParam, param: AxiosParam<number>) {
  send(getService().post<number>('/enviarEventosA3', monitorEsocialParam, getHeaders()), param);
}

export function downloadXmlEvento(monitorEsocialParam: MonitorEventoEsocialParam, param: AxiosParam<string>) {
  send(getService().post('/downloadXmlEvento', monitorEsocialParam), param);
}

export function validarOutrosEventosPeriodicos(monitorEsocialParam: MonitorEventoEsocialParam, param: AxiosParam<string>) {
  send(getService().post('/validarOutrosEventosPeriodicos', monitorEsocialParam), param);
}

export function gerarEsocialOutrosEventos(monitorEsocialParam: MonitorEventoEsocialParam, param: AxiosParam<string>) {
  send(getService().post<string>('/gerarEsocialOutrosEventos', monitorEsocialParam, getHeaders()), param);
}

export function findVinculosSincronizacao(sincronizacaoEsocialBxParam: SincronizacaoEsocialBxParam, param: AxiosParam<Vinculo[]>) {
  send(getService().post<Vinculo[]>('/findVinculosSincronizacao', sincronizacaoEsocialBxParam), param);
}

export function executarValidacaoEsocial(eSocialParam: EsocialParam, param: AxiosParam<string>) {
  send(getService().post<string>('/executarValidacaoEsocial', eSocialParam, getHeaders()), param);
}

export function sincronizarEvento(sincronizacaoEventoEsocial: SincronizacaoEventoEsocial, param: AxiosParam<number>) {
  send(getService().post<number>('/sincronizarEvento', sincronizacaoEventoEsocial, getHeaders()), param);
}

export function validarDocResponsavel(grrfParam: GrrfParam, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/validarDocResponsavel', grrfParam), param);
}

export function findSituacaoEventosVinculos(situacaoEventosEsocial: SituacaoEventosEsocial, param: AxiosParam<SituacaoEventosEsocial>) {
  send(getService().post<SituacaoEventosEsocial>('/findSituacaoEventosVinculos', situacaoEventosEsocial), param);
}

export function gerarEvtPendente(geracaoEventoPendenteParam: GeracaoEventoPendenteParam, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/gerarEvtPendente', geracaoEventoPendenteParam), param);
}

export function validarPercentualDependente(dependente: Dependente, param: AxiosParam<string>) {
  send(getService().post<string>('/validarPercentualDependente', dependente), param);
}

export function findSituacaoEventosVinculosDep(situacaoEventosEsocial: SituacaoEventosEsocial, param: AxiosParam<SituacaoEventosEsocial>) {
  send(getService().post<SituacaoEventosEsocial>('/findSituacaoEventosVinculosDep', situacaoEventosEsocial), param);
}

export function salvarEventoMultiEmpresas(geracaoEventoPendenteParam: GeracaoEventoPendenteParam, param: AxiosParam<number[]>) {
  send(getService().post<number[]>('/salvarEventoMultiEmpresas', geracaoEventoPendenteParam), param);
}

export function imprimirRelacaoEvtPendentes(geracaoEventoPendenteParam: GeracaoEventoPendenteParam, param: AxiosParam<string>) {
  send(getService().post<string>('/imprimirRelacaoEvtPendentes', geracaoEventoPendenteParam, getHeaders()), param);
}

export function findSituacaoEventosESocial(situacaoEventosEsocial: SituacaoEventosEsocial, param: AxiosParam<SituacaoEventosEsocial>) {
  send(getService().post<SituacaoEventosEsocial>('/findSituacaoEventosESocial', situacaoEventosEsocial), param);
}

export function gerarEventoPendenteWithXML(situacaoEventosEsocial: GeracaoEventoPendenteParam, param: AxiosParam<void>) {
  send(getService().post<void>('/gerarEventoPendenteWithXML', situacaoEventosEsocial), param);
}

export function findByQuadroHorario(quadroHorarioParam: QuadroHorarioParam, param: AxiosParam<QuadroHorarioDia[]>) {
  send(getService().post<QuadroHorarioDia[]>('/findByQuadroHorario', quadroHorarioParam), param);
}

export function gerarPeriodoEscalaAutomaticamente(quadroHorarioDia: QuadroHorarioDia, param: AxiosParam<QuadroHorarioDia[]>) {
  send(getService().post<QuadroHorarioDia[]>('/gerarPeriodoEscalaAutomaticamente', quadroHorarioDia), param);
}

export function validarFeriados(quadroHorarioParam: QuadroHorarioParam, param: AxiosParam<QuadroHorarioParam>) {
  send(getService().post<QuadroHorarioParam>('/validarFeriados', quadroHorarioParam), param);
}

export function persistQuadroHorario(quadroHorarioParam: QuadroHorarioParam, param: AxiosParam<QuadroHorario>) {
  send(getService().post<QuadroHorario>('/persistQuadroHorario', quadroHorarioParam), param);
}

export function provisoesDashboards(dashboardParam: DashboardParam, param: AxiosParam<DashboardResultParam>) {
  send(getService().post<DashboardParam>('/provisoesDashboards', dashboardParam), param);
}

export function persistResilicao(resilicao: Resilicao, param: AxiosParam<any>) {
  send(getService().post('/persistResilicao', resilicao), param);
}

export function findUltimoCalculoRealizado(idEmpresaMFP: number, referencia: string, param: AxiosParam<CalculoReferenciaExample>) {
  send(getService().get<CalculoReferenciaExample>(`/findUltimoCalculoRealizado/${idEmpresaMFP}/${referencia}`), param);
}

export function verificarAssinadorA3Executando(param: AxiosParam<InformacaoAssinadorA3>) {
  send(getLocalhostService().get<InformacaoAssinadorA3>('/info'), param);
}

export function enviarEsocialReinfA3APP(argumentos: any, param: AxiosParam<InformacaoAssinadorA3>) {
  send(getLocalhostService().post<InformacaoAssinadorA3>('/arquivodigital/esocialreinf', argumentos), param);
}

export function gerarBolsaQualificacao(
  bolsaQualificacaoExportacaoParam: BolsaQualificacaoExportacaoParam,
  usuario: number,
  param: AxiosParam<RuleMessage[]>
) {
  send(getService().post<RuleMessage[]>(`/gerarBolsaQualificacao/${usuario}`, bolsaQualificacaoExportacaoParam, getHeaders()), param);
}

export function ajustarDtPeriodoAquisitivo(ferias: Ferias, param: AxiosParam<string>) {
  send(getService().post<string>('/ajustarDtPeriodoAquisitivo', ferias), param);
}

export function existeCalculoReferencia(calculoParam: CalculoParam, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/existeCalculoReferencia', calculoParam), param);
}

export function verificarPendenciasMFP(usuario: Usuario, param: AxiosParam<void>) {
  send(getService().post<void>('/verificarPendencias', usuario, getHeaders()), param);
}

export function getTrabalhadorCargoAtivo(trabalhadorCargoAtivoParam: TrabalhadorCargoAtivoParam, param: AxiosParam<Vinculo>) {
  send(getService().post<Vinculo>('/getTrabalhadorCargoAtivo', trabalhadorCargoAtivoParam), param);
}

export function getComparativoMemoriaIRRF(memoriaCalculoParam: MemoriaCalculoParam, param: AxiosParam<ComparativoMemoriaCalculoIRRFVinculoVO>) {
  send(getService().post<ComparativoMemoriaCalculoIRRFVinculoVO>('/getComparativoMemoriaIRRF', memoriaCalculoParam), param);
}

export function getTrabalhadoresAtivos(idEmpresaMFP: number, param: AxiosParam<Vinculo[]>) {
  send(getService().get<Vinculo[]>(`/getTrabalhadoresAtivos/${idEmpresaMFP}`), param);
}

export function validarFaseEnvioEsocial(validacaoFaseEsocial: ValidacaoFaseEsocial, param: AxiosParam<ValidacaoFaseEsocial>) {
  send(getService().post<ValidacaoFaseEsocial>('/validarFaseEnvioEsocial', validacaoFaseEsocial), param);
}

export function verificarAfastamentoExclusao(
  idEmpresa: number,
  idMovimentacao: number,
  idVinculo: number,
  isAfastamento: boolean,
  param: AxiosParam<boolean>
) {
  const formDataSend = new FormData();
  formDataSend.append('idEmpresa', String(idEmpresa));
  formDataSend.append('idMovimentacao', String(idMovimentacao));
  formDataSend.append('idVinculo', String(idVinculo));
  formDataSend.append('isAfastamento', String(isAfastamento));
  send(getService().post<boolean>('/verificarAfastamentoExclusao', formDataSend), param);
}

export function getEsocialSemaforoStatus(param: AxiosParam<EsocialSemaforoStatusParam>) {
  send(getService().get<EsocialSemaforoStatusParam>('/getEsocialSemaforoStatus'), param);
}

export function gerarEvtPendenteCAT(cat: Cat, param: AxiosParam<ESocialEventoPendente>) {
  send(getService().post<ESocialEventoPendente>('/gerarEvtPendenteCAT', cat), param);
}

export function gerarEvtPendenteMonitoramentoSaude(vinculoExameOcupacional: VinculoExameOcupacional, param: AxiosParam<ESocialEventoPendente>) {
  send(getService().post<ESocialEventoPendente>('/gerarEvtPendenteMonitoramentoSaude', vinculoExameOcupacional), param);
}

export function gerarEvtPendenteExameToxicologico(exameToxicologico: ExameToxicologico, param: AxiosParam<ESocialEventoPendente>) {
  send(getService().post<ESocialEventoPendente>('/gerarEvtPendenteExameToxicologico', exameToxicologico), param);
}

export function gerarEvtPendenteCondicaoAmbiental(condicaoAmbientalTrabalho: CondicaoAmbientalTrabalho, param: AxiosParam<ESocialEventoPendente>) {
  send(getService().post<ESocialEventoPendente>('/gerarEvtPendenteCondicaoAmbiental', condicaoAmbientalTrabalho), param);
}

export function getEventosToImportacaoXMLEventos(data: ImportacaoParam, param: AxiosParam<any[]>) {
  send(getService().post<any[]>('/getEventosToImportacaoXMLEventos', data, getHeaders()), param);
}

export function salvarImportacaoXMLEventos(data: ImportacaoXMLEventosEsocialParam, param: AxiosParam<number>) {
  send(getService().post<number>('/salvarImportacaoXMLEventos', data, getHeaders()), param);
}

export function isHorista(idVinculo: number, referencia: string, param: AxiosParam<boolean>) {
  const formDataSend = new FormData();
  formDataSend.append('idVinculo', String(idVinculo));
  formDataSend.append('referencia', referencia);
  send(getService().post<boolean>('/isHorista', formDataSend), param);
}

export function persistOperacaoLote(
  entities: Basic[],
  dtoClassName: string,
  idUsuario: number = null,
  tipoOperacao: string,
  param: AxiosParam<number>
) {
  send(
    getService().post<number>(`/persistOperacaoLote/${idUsuario}/${tipoOperacao}`, { data: entities, className: dtoClassName }, getHeaders()),
    param
  );
}

export function infoConsultaProtocolosESocial(idEmpresaMFP: number, param: AxiosParam<InfoConsultaProtocolosAutomatica>) {
  send(getService().get<InfoConsultaProtocolosAutomatica>(`/infoConsultaProtocolosESocial/${idEmpresaMFP}`), param);
}

export function verificarExclusaoConvocTrabIntermt(idEmpresa: number, idMovimentacao: number, param: AxiosParam<boolean>) {
  const formDataSend = new FormData();
  formDataSend.append('idEmpresa', String(idEmpresa));
  formDataSend.append('idMovimentacao', String(idMovimentacao));
  send(getService().post<boolean>('/verificarExclusaoConvocTrabIntermt', formDataSend), param);
}

export function importarLancamentoSimplificadoCSV(data: ImportacaoEventoSimplificadoParam, param: AxiosParam<VinculoEvento[]>) {
  send(getService().post<VinculoEvento[]>('/importarLancamentoSimplificadoCSV', data, getHeaders()), param);
}

export function getDadosEventoSimplificado(data: ExportacaoEventoSimplificadoParam, param: AxiosParam<VinculoEvento[]>) {
  send(getService().post<VinculoEvento[]>('/getDadosEventoSimplificado', data), param);
}

export function ajustarMapaEventosOrdenados(idGrupoEvento: number, param: AxiosParam<void>) {
  send(getService().post<void>(`/ajustarMapaEventosOrdenados/${idGrupoEvento}`), param);
}

export function gerarEvtPendenteS2230(geracaoEventoPendenteParam: GeracaoEventoPendenteParam, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/gerarEvtPendenteS2230', geracaoEventoPendenteParam), param);
}

export function deleteEventosPendentesESocial(idsEntidadeRelacionada: number[], param: AxiosParam<void>) {
  send(getService().post<void>('/deleteEventosPendentesESocial', idsEntidadeRelacionada), param);
}

export function gerarEsocialOutrosEventosA3(monitorEsocialParam: MonitorEventoEsocialParam, param: AxiosParam<InformacaoLote>) {
  send(getService().post<InformacaoLote>('/gerarEsocialOutrosEventosA3', monitorEsocialParam, getHeaders()), param);
}

export function buscarVinculoS2230(idEntidadeRelacionada: number, isFerias: boolean, param: AxiosParam<Vinculo>) {
  send(getService().get<Vinculo>(`/buscarVinculoS2230/${idEntidadeRelacionada}/${isFerias}`), param);
}

export function consultarSituacaoProtocolo(protocoloEsocial: ProtocoloEsocial, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/consultarSituacaoProtocolo', protocoloEsocial, getHeaders()), param);
}

export function validarGeracaoEventosPendentesTransferencia(transferenciaVinculo: TransferenciaVinculo, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/validarGeracaoEventosPendentesTransferencia', transferenciaVinculo), param);
}

export function ajustarContratosVencidos(usuario: Usuario, param: AxiosParam<void>) {
  send(getService().post<void>('/ajustarContratosVencidos', usuario, getHeaders()), param);
}

export function buscarEnviosAutomaticosESocial(param: AxiosParam<EnvioAutomaticoESocialParam[]>) {
  send(getService().get<EnvioAutomaticoESocialParam[]>('/buscarEnviosAutomaticosESocial'), param);
}

export function buscarEventosPeriodicosPendentes(param: AxiosParam<EventosPendentesEnvioAutomaticoESocialParam[]>) {
  send(getService().get<EventosPendentesEnvioAutomaticoESocialParam[]>('/buscarEventosPeriodicosPendentes'), param);
}

export function enviarEventosPeriodicosESocial(envioParam: EventosPendentesEnvioAutomaticoESocialParam[], param: AxiosParam<void>) {
  send(getService().post<void>('/enviarEventosPeriodicosESocial', envioParam), param);
}

export function atualizarAgendamentosEnvioAutomatico(agendamentoParam: AgendamentoEnvioAutomaticoESocialParam, param: AxiosParam<void>) {
  send(getService().post<void>('/atualizarAgendamentosEnvioAutomatico', agendamentoParam), param);
}

export function habilitarEnvioAutomaticoESocialTodasEmpresas(
  todasEmpresas: boolean,
  empresaESocialConfig: EmpresaESocialConfig,
  param: AxiosParam<void>
) {
  send(getService().post<void>(`/habilitarEnvioAutomaticoESocialTodasEmpresas/${todasEmpresas}`, empresaESocialConfig), param);
}

export function getUltimaPaginaAcessada(idUsuario: number, param: AxiosParam<UsuarioHistAcesso>) {
  send(getService().get<UsuarioHistAcesso>(`/getUltimaPaginaAcessada/${idUsuario}`), param);
}

export function incluirTomadorServicoLote(loteParam: VinculoTomadorServicoLoteParam, param: AxiosParam<number>) {
  send(getService().post<number>('/incluirTomadorServicoLote', loteParam, getHeaders()), param);
}

export function updateMultiplosVinculos(idVinculo: number, param: AxiosParam<void>) {
  send(getService().post<void>(`/updateMultiplosVinculos/${idVinculo}`, idVinculo, getHeaders()), param);
}

export function buscarTrabalhadoresOperacaoLote(loteParam: PainelControleParam, param: AxiosParam<number>) {
  send(getService().post<number>('/buscarTrabalhadoresOperacaoLote', loteParam), param);
}

export function gerarEvtPendenteProcessoTrabalhista(processoTrabalhista: ProcessoTrabalhista, param: AxiosParam<ESocialEventoPendente>) {
  send(getService().post<ESocialEventoPendente>('/gerarEvtPendenteProcessoTrabalhista', processoTrabalhista, getHeaders()), param);
}

export function gerarEvtPendenteTributosProcessoTrabalhista(
  tributosProcessoTrabalhista: TributosProcessoTrabalhista,
  param: AxiosParam<ESocialEventoPendente>
) {
  send(getService().post<ESocialEventoPendente>('/gerarEvtPendenteTributosProcessoTrabalhista', tributosProcessoTrabalhista), param);
}

export function buscarRescisoesGRRF(grrfParam: GrrfParam, hashId: string, hashIdMsg: string, param: AxiosParam<void>) {
  send(getService().post<void>(`/buscarRescisoesGRRF/${hashId}/${hashIdMsg}`, grrfParam), param);
}

export function existeEventoPendente(
  tipoEvento: string,
  idEntidadeRelacionada: number,
  idEmpresaMFP: number,
  tipoAmbiente: string,
  param: AxiosParam<boolean>
) {
  const formDataSend = new FormData();
  formDataSend.append('tipoEvento', String(tipoEvento));
  formDataSend.append('idEntidadeRelacionada', String(idEntidadeRelacionada));
  formDataSend.append('idEmpresaMFP', String(idEmpresaMFP));
  formDataSend.append('tipoAmbiente', String(tipoAmbiente));
  send(getService().post<boolean>('/existeEventoPendente', formDataSend), param);
}

export function podeGerarS3000Vinculo(geracaoParam: GeracaoEventoPendenteParam, param: AxiosParam<boolean>) {
  send(getService().post<boolean>('/podeGerarS3000Vinculo', geracaoParam), param);
}

export function updateValoresPercentualEmpresaAutonomo(idEmpresaMfp: number, percentual: number, param: AxiosParam<void>) {
  const formDataSend = new FormData();
  formDataSend.append('idEmpresaMfp', String(idEmpresaMfp));
  formDataSend.append('percentual', String(percentual));
  send(getService().post<void>('/updateValoresPercentualEmpresaAutonomo', formDataSend), param);
}

export function findAllEmpresas(referencia: string, hashId: string, hashIdMsg: string, param: AxiosParam<number>) {
  send(getService().get<number>(`/findAllEmpresas/${referencia}/${hashId}/${hashIdMsg}`), param);
}

export function replicarNotificacoes(replicarNotificacaoParam: ReplicarNotificacaoParam, param: AxiosParam<[]>) {
  send(getService().post<RuleMessage[]>('/replicarNotificacoes', replicarNotificacaoParam), param);
}

export function validaDiasEscala(validaEscalaParam: ValidaEscalaParam, param?: AxiosParam<void>) {
  send(getService().post<void>('/validaDiasEscala', validaEscalaParam), param);
}

export function buscarPagamentosPlanoSaudeDependentes(
  dependentesParam: PlanoSaudeDependentesParam,
  param: AxiosParam<PlanoSaudeDependentesValoresParam[]>
) {
  send(getService().post<PlanoSaudeDependentesValoresParam[]>('/buscarPagamentosPlanoSaudeDependentes', dependentesParam), param);
}

export function atualizarValorPagamentosPlanoSaudeDependentes(valoresParam: PlanoSaudeDependentesValoresParam[], param: AxiosParam<void>) {
  send(getService().post<void>('/atualizarValorPagamentosPlanoSaudeDependentes', valoresParam), param);
}

export function trocasContratoEmpresa(aplicarTodasEmpresas: boolean, notificarAlterar: boolean, idEmpresa: number, param: AxiosParam<void>) {
  const formDataSend = new FormData();
  formDataSend.append('aplicarTodasEmpresas', String(aplicarTodasEmpresas));
  formDataSend.append('notificarAlterar', String(notificarAlterar));
  formDataSend.append('idEmpresa', String(idEmpresa));
  send(getService().post<void>('/trocasContratoEmpresa', formDataSend), param);
}

export function alterarDadosTrocaContrato(idVinculo: number, dataDemissao: string, param: AxiosParam<void>) {
  const formDataSend = new FormData();
  formDataSend.append('dataDemissao', dataDemissao);
  send(getService().post<void>(`/alterarDadosTrocaContrato/${idVinculo}`, formDataSend), param);
}

export function calcularProvisao(calculoProvisaoParam: CalculoProvisaoParam, param: AxiosParam<number>) {
  send(getService().post<number>('/calcularProvisao', calculoProvisaoParam, getHeaders()), param);
}

export function existeCalculoProvisao(referencia: string, idEmpresaMfp: number, idsVinculos: number[], param: AxiosParam<boolean[]>) {
  send(getService().post<boolean[]>(`/existeCalculoProvisao/${referencia}/${idEmpresaMfp}`, idsVinculos), param);
}

export function excluirCalculosProvisao(idsCalculosProvisao: number[], idEmpresaMfp: number, param: AxiosParam<void>) {
  send(getService().post<void>(`/excluirCalculosProvisao/${idEmpresaMfp}`, idsCalculosProvisao), param);
}

export function existeCalculoNaReferencia(referencia: string, idEmpresaMfp: number, tipoCalculo: string, param: AxiosParam<boolean>) {
  send(getService().post<boolean>(`/existeCalculoNaReferencia/${referencia}/${idEmpresaMfp}/${tipoCalculo}`), param);
}

export function envioAutomaticoComErro(param: AxiosParam<boolean>) {
  send(getService().get<boolean>('/envioAutomaticoComErro'), param);
}

export function transferirVinculoOutroSistema(transferencia: TransferenciaVinculo, param: AxiosParam<number>) {
  send(getService().post<number>('/transferirVinculoOutroSistema', transferencia, getHeaders()), param);
}

export function alterarPlanoSaudeLote(isInclusao: boolean, loteParam: PlanoSaudeLoteParam, param: AxiosParam<number>) {
  send(getService().post<number>(`/alterarPlanoSaudeLote/${isInclusao}`, loteParam, getHeaders()), param);
}

export function podeExcluirVinculo(idVinculo: number, param: AxiosParam<void>) {
  send(getService().post<void>(`/podeExcluirVinculo/${idVinculo}`), param);
}

export function enviarEventoExclusaoVinculo(idVinculo: number, idUsuario: number, param: AxiosParam<boolean>) {
  send(getService().post<boolean>(`/enviarEventoExclusaoVinculo/${idVinculo}/${idUsuario}`, null, getHeaders()), param);
}

export function gerarPendenteRescisaoSemCalculo(idRescisao: number, idUsuario: number, param: AxiosParam<void>) {
  send(getService().post<void>(`/gerarPendenteRescisaoSemCalculo/${idRescisao}/${idUsuario}`), param);
}

export function buscarVinculosPlanoSaude(filtroParam: VinculoEventoPlanoSaudeFiltroParam, param: AxiosParam<VinculoEventoPlanoSaudeResultParam[]>) {
  send(getService().post<VinculoEventoPlanoSaudeResultParam[]>('/buscarVinculosPlanoSaude', filtroParam), param);
}

export function isSalarioPeriodico(idVinculo: number, referencia: string, param: AxiosParam<boolean>) {
  send(getService().post<boolean>(`/isSalarioPeriodico/${idVinculo}/${referencia}`), param);
}

export function updateDataFimEvento(idsEventosParaFinalizar: number[], refInicial: string, param: AxiosParam<void>) {
  send(getService().post<void>(`/updateDataFimEvento/${refInicial}`, idsEventosParaFinalizar), param);
}

export function verificarVinculoEvtAdmissao(vinculo: Vinculo[], param: AxiosParam<BasicESocial[]>) {
  send(getService().post<BasicESocial[]>('/verificarVinculoEvtAdmissao', vinculo), param);
}

export function buscarEventosPlanoSaudeVinculo(buscaParam: VinculoEventoPlanoSaudeBuscaParam, param: AxiosParam<VinculoEventoPlanoSaudeResultParam>) {
  send(getService().post<VinculoEventoPlanoSaudeResultParam>('/buscarEventosPlanoSaudeVinculo', buscaParam), param);
}

export function salvarPlanoSaudeSimplificado(salvarParam: SalvarPlanoSaudeSimplificadoParam, param: AxiosParam<string[]>) {
  send(getService().post<string[]>('/salvarPlanoSaudeSimplificado', salvarParam), param);
}
